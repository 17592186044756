import Vue from 'vue'
import VueRouter from 'vue-router'
import TransactionProject from '../views/TransactionProject'
import TransactionRecap from '../views/TransactionRecap'
import Test from '../views/Test'

Vue.use(VueRouter)

const routes = [
	{
		path: '/project',
		name: 'project',
		component: TransactionProject
	},
	{
		path: '/projectrecap/:projectNumber',
		name: 'projectrecap',
		component: TransactionRecap
	},
	{
		path: '/test',
		name: 'Test',
		component: Test
	}
]

const router = new VueRouter({
	routes
})

export default router
