<template>
    <div>
        <v-textarea v-if="shouldBeShown('object')" :label="computeLabel('object')" :rules="computeRules('object')" v-model="form.object"/>
        <v-text-field v-if="shouldBeShown('volume')" :label="computeLabel('volume')" :rules="computeRules('volume')" v-model="form.volume"/>
        <v-text-field v-if="shouldBeShown('currencies')" :label="computeLabel('currencies')" :rules="computeRules('currencies')" v-model="form.currencies"/>
        <v-select v-if="shouldBeShown('period')" :items="computeSelectList('periodChoices')" :label="computeLabel('period')"
                  :rules="computeRules('period')" v-model="form.period"/>
    </div>
</template>

<script>

import AbstractFormPart from "./AbstractFormPart";

const EMPTY = {
		object: "",
		volume: "",
		period: "",
		currencies: ""
	};

	export default {
		name: "FormPartBusinessRelation",
		extends: AbstractFormPart,
		data() {
			return {
				formId: 'businessRelation',
				empty: EMPTY
			}
		}
	}
</script>

<style scoped>

</style>
