import LocaleHelper from "./LocaleHelper";

let currentLocale = LocaleHelper.getCurrentLocale();

export default function (value, currency, forceNumberOfSignificantDigits) {
	if (!forceNumberOfSignificantDigits) {
		return value.toLocaleString(currentLocale, {style: 'currency', currency: currency});
	} else {
		return value.toLocaleString(currentLocale, {style: 'decimal', minimumSignificantDigits: forceNumberOfSignificantDigits, maximumSignificantDigits: forceNumberOfSignificantDigits}) + ' ' + currency;
	}
}
