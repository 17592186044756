<template>
    <form-container :modules="['naturalPerson', 'signature']" :required-fields="['naturalPerson.firstName']"/>
</template>

<script>

	import FormContainer from "../components/identityform/FormContainer";
	export default {
		name: "Test",
		components: {FormContainer},
		data() {
			return {}
		}
	}
</script>

<style scoped>

</style>
