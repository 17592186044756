<template>
    <v-checkbox :label="computeLabel()" :rules="computeRules()" v-model="form.certify"/>
</template>

<script>
	import AbstractFormPart from "./AbstractFormPart";

	const EMPTY = {
		certify: false
    }

	export default {
		name: "FormPartCertify",
        extends: AbstractFormPart,
        data() {
			return {
				empty: EMPTY,
                formId: 'certify'
            }
        }
	}
</script>

<style scoped>

</style>
