<template>
    <v-container>
        <v-row>
            <v-col>
                <v-text-field :label="'1 ' + baseCurrency.iso.toUpperCase() + ' ='"
                              :value="(foreignCurrency.base * (isClientSelling ? foreignCurrency.buyRateReverse : foreignCurrency.sellRateReverse))  | formatCurrency(foreignCurrency.iso.toUpperCase(), 7)"
                              class="v-text-field-hide-details"
                              outlined
                              readonly/>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-text-field :label="foreignCurrency.base + ' ' + foreignCurrency.iso.toUpperCase() + ' ='"
                              :value="(isClientSelling ? foreignCurrency.buyRate : foreignCurrency.sellRate) | formatCurrency(baseCurrency.iso.toUpperCase(), 7)"
                              class="v-text-field-hide-details"
                              outlined
                              readonly/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
	export default {
		name: "CurrencyRateDisplay",
    // ATTENTION : isClientSelling indique que le CLIENT vend, donc le bureau ACHETE
		props: ['baseCurrency', 'foreignCurrency', 'isClientSelling']
	}
</script>

<style>

</style>
