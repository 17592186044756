const FALLBACK_LOCALE = 'en';

import * as fr from '../i18n/fr.json';
import * as en from '../i18n/en.json';

const messages = {
	'en': {...en}.default,
	'fr': {...fr}.default
};

const availableLanguages = ['ar','cs','da','de','en','es','fi','fr','it','id','ja','kn','ko','kok','nb','nl','pt','ru','sk','sl','sv','ta','vi','zh']

export default {
	getMessages() {
		return messages;
	},
	getSupportedLocales() {
		return Object.keys(messages);
	},
	getCurrentLocale() {
		let browserLocale = navigator.language.split('-')[0];
		if (availableLanguages.includes(browserLocale)) {
			return browserLocale;
		} else {
			return FALLBACK_LOCALE;
		}
	}
}
