<template>
    <v-container>
        <v-card id="recap-title">
            <v-card-title class="headline grey lighten-2">{{$t('transactionRecap.orderRefTitle')}}</v-card-title>
            <v-card-text class="blue white--text text-center font-weight-thin display-4 pa-12">
              <pre>{{$route.params.projectReference | uppercase}}</pre>
            </v-card-text>
        </v-card>
<!--        <v-card v-if="$route.params.pushResponse">-->
<!--            <v-card-title></v-card-title>-->
<!--        </v-card>-->
    </v-container>
</template>

<script>
import * as eases from "vuetify/es5/services/goto/easing-patterns";

export default {
		name: "TransactionRecap",
		mounted() {
			this.$vuetify.goTo("#recap-title", {easing: eases.easeOutCubic})
		},
    filters: {
		  uppercase: (v)=>v.toUpperCase()
    }
	}
</script>

<style scoped>
</style>
