import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import LocaleHelper from "../utils/LocaleHelper";

Vue.use(Vuetify);

let currentLocale = LocaleHelper.getCurrentLocale();

let vuetifyLocales = {};
for (const supportedLocale of LocaleHelper.getSupportedLocales()) {
    let lang = require('vuetify/es5/locale/'+supportedLocale);
    if (lang) {
        vuetifyLocales[supportedLocale] = lang.default;
    }
}

export default new Vuetify({
    lang: {
      locales: vuetifyLocales,
      current: currentLocale,
    },
  icons: {
    iconfont: 'mdi',
  },
});
