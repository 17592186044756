<template>
    <div>
        <v-text-field v-if="shouldBeShown('street')" :label="computeLabel('street')" :rules="computeRules('street')" v-model="form.street"/>
        <v-text-field v-if="shouldBeShown('city')" :label="computeLabel('city')" :rules="computeRules('city')" v-model="form.city"/>
        <v-text-field v-if="shouldBeShown('zipCode')" :label="computeLabel('zipCode')" :rules="computeRules('zipCode')" v-model="form.zipCode"/>
        <v-text-field v-if="shouldBeShown('state')" :label="computeLabel('state')" :rules="computeRules('state')" v-model="form.state"/>
        <v-text-field v-if="shouldBeShown('country')" :label="computeLabel('country')" :rules="computeRules('country')" v-model="form.country"/>
    </div>
</template>

<script>

import AbstractFormPart from "./AbstractFormPart";

const EMPTY = {
		street: "",
		zipCode: "",
		city: "",
		state: "",
		country: ""
	};

	export default {
		name: "FormPartAddress",
        extends: AbstractFormPart,
        data() {
			return {
                formId: 'address',
				empty: EMPTY
            }
        }
	}
</script>

<style scoped>

</style>
