<template>
    <div>
        <v-select v-if="shouldBeShown('professionalStatus')" :items="computeSelectList('professionalStatusChoices')" :label="computeLabel('professionalStatus')"
                  :rules="computeRules('professionalStatus')" v-model="form.professionalStatus"/>
        <v-text-field v-if="shouldBeShown('employer')" :label="computeLabel('employer')" :rules="computeRules('employer')" v-model="form.employer"/>
        <v-text-field v-if="shouldBeShown('function')" :label="computeLabel('function')" :rules="computeRules('function')" v-model="form.function"/>
        <v-autocomplete v-if="shouldBeShown('lineOfBusiness')" :items="computeSelectList('lineOfBusinessChoices')" :label="computeLabel('lineOfBusiness')"
                        :rules="computeRules('lineOfBusiness')" v-model="form.lineOfBusiness"/>
        <country-input v-if="shouldBeShown('employerCountry')" :label="computeLabel('employerCountry')" :rules="computeRules('employerCountry')"
                      v-model="form.employerCountry"/>
        <country-input v-if="shouldBeShown('countryOfBusiness')" :label="computeLabel('countryOfBusiness')" :rules="computeRules('countryOfBusiness')"
                      v-model="form.countryOfBusiness"/>
        <v-select v-if="shouldBeShown('annualNetIncome')" :items="computeSelectList('annualNetIncomeChoices', false)"
                  :label="computeLabel('annualNetIncome')" :rules="computeRules('annualNetIncome')"
                  v-model="form.annualNetIncome"/>
        <v-select v-if="shouldBeShown('estate')" :items="computeSelectList('estateChoices', false)"
                  :label="computeLabel('estate')" :rules="computeRules('estate')" v-model="form.estate"/>
    </div>
</template>

<script>

import AbstractFormPart from "./AbstractFormPart";
import CountryInput from "./CountryInput";

const EMPTY = {
		professionalStatus: "",
		employer: "",
		function: "",
		lineOfBusiness: "",
		employerCountry: "",
		countryOfBusiness: "",
		annualNetIncome: "",
		estate: ""
	};

	export default {
		name: "FormPartWorkSituation",
		components: {CountryInput},
		extends: AbstractFormPart,
		data() {
			return {
				formId: 'workSituation',
				empty: EMPTY
			}
		}
	}
</script>

<style scoped>

</style>
