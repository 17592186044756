<template>
    <div>
        <v-text-field v-if="shouldBeShown('companyName')" :label="computeLabel('companyName')" :rules="computeRules('companyName')" v-model="form.companyName"/>
        <v-text-field v-if="shouldBeShown('rcsRegistration')" :label="computeLabel('rcsRegistration')" :rules="computeRules('rcsRegistration')" v-model="form.rcsRegistration"/>
        <v-text-field v-if="shouldBeShown('phoneNumber')" :label="computeLabel('phoneNumber')" :rules="computeRules('phoneNumber')" type="tel" v-model="form.phoneNumber"/>
    </div>
</template>

<script>

import AbstractFormPart from "./AbstractFormPart";

const EMPTY = {
		companyName: "",
		rcsRegistration: "",
		phoneNumber: ""
	};

	export default {
		name: "FormPartLegalEntity",
		extends: AbstractFormPart,
		data() {
			return {
				formId: 'legalEntity',
				empty: EMPTY
			}
		}
	}
</script>

<style scoped>

</style>
