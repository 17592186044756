<template>
    <div>
        <v-container>
            <v-row>
                <v-col>
                    <v-card :disabled="disabled" :color="selected===1 ? 'primary' : null" :dark="selected===1"
                            :elevation="selected===1 ? 6 : 2" @click="select(1)" height="100%">
                        <v-card-text>
                            {{$t('operationTypeSelector.options.localToForeign',
                            {iso:config.transactions.baseCurrency.iso.toUpperCase()})}}
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card :disabled="disabled" :color="selected===2 ? 'primary' : null" :dark="selected===2"
                            :elevation="selected===2 ? 6 : 2" @click="select(2)" height="100%">
                        <v-card-text>
                            {{$t('operationTypeSelector.options.foreignToLocal',
                            {iso:config.transactions.baseCurrency.iso.toUpperCase()})}}
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
	export default {
		name: "OperationTypeSelector",
		data() {
			return {
				selected: null
			}
		},
		props: ['config', 'disabled'],
        methods: {
			select(selected) {
				this.selected = selected;
				this.$emit('selected', selected);
            },
			reset() {
				this.selected = null;
            }
        }
	}
</script>

<style scoped>

</style>
