<template>
    <v-container>
        <v-row>
            <v-col>
                <div class="currency-container">
                    <v-card :elevation="selectedInList === cur ? 4 : 2" :key="cur.iso" @click="onCurrencyClicked(cur)"
                            class="ma-1"
                            height="70" v-bind:class="{'selected': cur===selectedInList}"
                            v-for="cur in favoritesCurrencies"
                            width="70">
                        <flag :iso="cur.flag" :squared="false"/>
                        <span>{{cur.iso}}</span>
                    </v-card>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-autocomplete :item-text="formatCurrency" :items="exchangeRates" :label="$t('currencySelector.select')"
                          class="v-text-field-hide-details" outlined return-object v-model="selectedInList"/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import CountryHelper from "../../utils/CountryHelper";

export default {
		name: "CurrencySelector",
		data() {
			return {
				selectedInList: null,
			}
		},
    // ATTENTION : isClientSelling indique que le CLIENT vend, donc le bureau ACHETE
		props: [
			'exchangeRates', 'isClientSelling'
		],
		computed: {
			favoritesCurrencies() {
				let favorites = this.exchangeRates.filter(cur => cur.favorite);
				if (this.isClientSelling === true) {
          favorites = favorites.filter(cur => cur.forBuying !== false) // on filtre les devises explicitements données comme n'étant pas dispo à l'achat par le bureau
        } else {
          favorites = favorites.filter(cur => cur.forSale !== false) // on filtre les devises explicitements données comme n'étant pas dispo à la vente par le bureau
        }
				if (this.selectedInList) {
					if (favorites.indexOf(this.selectedInList) < 0) {
						favorites.push(this.selectedInList);
					}
				}
				return favorites;
			}
		},
		watch: {
			selectedInList(newVal) {
				this.$emit('selected', newVal);
			}
		},
		methods: {
			resetSelection() {
				this.selectedInList = null;
			},
			onCurrencyClicked(currency) {
				this.selectedInList = currency;
			},
			formatCurrency(c) {
				return c.iso + ' - ' + c.name + ' (' + CountryHelper.getCountryName(c.flag) + ')';
			}
		}
	}
</script>

<style scoped>

    .currency-container div.selected {
        transform: scale(1.1);
        background-color: #1976d2;
    }

    .currency-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .currency-container div {
        overflow: hidden;
        transition-property: transform, background-color;
        transition-duration: .3s;
        background-color: #EEE;
    }

    .currency-container div :nth-child(1) {
        display: block;
        text-align: center;
        font-size: 53px;
    }

    .currency-container div :nth-child(2) {
        display: block;
        height: 100%;
        text-align: center;
        font-size: 12px;
    }

    .currency-container div.selected :nth-child(2) {
        font-weight: bolder;
        color: #FFF;
    }

</style>
