<template>
    <div class="signature-zone elevation-1" v-bind:class="{'invalid': isSignatureInvalid}">
        <span @click="reset">{{$t('common.erase')}}</span>
        <span v-if="isRequired">({{$t('common.requiredFieldShort')}})</span>
        <vue-signature-pad ref="signaturePad" :options="{onEnd:signatureChanged, onBegin: signatureChanged}"/>
    </div>
</template>

<script>

	export default {
		name: "FormPartSignature",
        props: ['isRequired'],
        data() {
			return {
				isEmpty: true
            }
        },
        computed: {
			isSignatureInvalid() {
				return !!this.isRequired && this.isEmpty
            }
        },
        methods: {
			reset() {
				this.isEmpty = true;
				this.$refs.signaturePad.clearSignature();
				this.$emit('change', null, 'signature')
            },
            validate() {
				return !this.isSignatureInvalid;
            },
			signatureChanged() {
				this.isEmpty = this.$refs.signaturePad.isEmpty()
                this.$emit('change', this.$refs.signaturePad.saveSignature().data, 'signature')
            }
        }
	}
</script>

<style scoped>

    .signature-zone {
        box-sizing: border-box;
        /*border: #ced4da 1px solid;*/
        background-color: #FFF;
        height: 13em;
        border-radius: .25em;
    }

    .signature-zone.invalid {
        background-color: #ffe6e8;
    }

    .signature-zone span:nth-child(1) { /** Bouton Effacer **/
        float: right;
        font-size: .8em;
        color: #FFF;
        padding: 0 .3em;
        border-radius: 0 .25rem 0 .25rem;
        background-color: #7f7f7f;
        cursor: pointer;
    }
    .signature-zone span:nth-child(2) { /** Label requis **/
        float: right;
        font-size: .8em;
        margin-right: .5em;
    }
</style>
