export default {
	isNumeric: function (n) {
		return !isNaN(parseFloat(n)) && isFinite(n);
	},
	compareObjectOnStrProperty: function (a, b, propertyName) {
		if (a[propertyName] < b[propertyName]) {
			return -1;
		}
		if (a[propertyName] > b[propertyName]) {
			return 1;
		}
		return 0;
	}
}
