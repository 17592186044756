<template>
    <v-row>
        <v-col>
            <v-card :disabled="disabled">
                <v-card-title v-if="title">{{title}}</v-card-title>
                <v-col>
                    <slot/>
                </v-col>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
	export default {
		name: "FormRow",
        props:['title', 'disabled']
	}
</script>

<style scoped>

</style>
