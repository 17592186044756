import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Config from './config'
import axios from 'axios'
import i18n from './plugins/i18n'
import vuetify from './plugins/vuetify';
import FlagIcon from 'vue-flag-icon'
import VueSignaturePad from 'vue-signature-pad';
import formatCurrencyVueFilter from "./utils/FormatCurrencyVueFilter";
import VueTimers from "vue-timers"


Vue.use(FlagIcon);
Vue.use(VueSignaturePad);
Vue.use(VueTimers)

Vue.filter('formatCurrency', formatCurrencyVueFilter);

Vue.config.productionTip = false
Vue.prototype.$config = Config
Vue.prototype.$http = axios

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
