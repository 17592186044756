<template>
    <v-container>
        <v-row>
            <v-col>
                <v-text-field
                        :id="nodePrefix+'base-input'" clearable
                        :label="isClientSelling ? $t('currencyForm.foreignCurrencyInputLabelSell') : $t('currencyForm.baseCurrencyInputLabelBuy')"
                        @focus="onInputFocus" class="v-text-field-hide-details"
                        outlined ref="clientGiveQuantityInput" type="number" v-model="clientGiveQuantity">
                    <template v-slot:append><span class="v-text-field__append-text">{{fromIso}}</span></template>
                </v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-text-field
                        :id="nodePrefix+'target-input'" clearable
                        :label="isClientSelling ? $t('currencyForm.baseCurrencyInputLabelSell') : $t('currencyForm.foreignCurrencyInputLabelBuy')"
                        @focus="onInputFocus" class="v-text-field-hide-details"
                        outlined ref="clientGetQuantityInput" type="number" v-model="clientGetQuantity">
                    <template v-slot:append><span class="v-text-field__append-text">{{toIso}}</span></template>
                </v-text-field>
            </v-col>
        </v-row>
        <v-row no-gutters v-if="errorAmountTooLow">
          <v-col>
            <p class="caption red--text">{{ $t('currencyForm.amountTooLowError') }}</p>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="fixedFee">
          <v-col>
            <p class="caption">
              {{ $t('currencyForm.fixedFeeApplied', {feeAmount: formatCurrency(fixedFee, baseCurrency.iso.toUpperCase())}) }}</p>
          </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn @click="onRoundAmountsClicked" block v-if="needsRounding">
                    {{$t('currencyForm.computeRounding')}}
                </v-btn>
                <v-dialog v-else v-model="nextDialog" width="500">
                    <template v-slot:activator="{ on }">
                        <v-btn :disabled="!nextEnabled" :loading="sendingProject" block color="primary" v-on="on">
                            {{nextButtonLabel}}
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>{{$t('currencyForm.nextDialogTitle')}}</v-card-title>
                        <v-card-text>{{$t('currencyForm.nextDialog')}}</v-card-text>
                        <v-divider/>
                        <v-card-actions>
                            <v-spacer/>
                            <v-btn @click="onNextDialogOkClicked" text>{{$t('common.continue')}}</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Commons from "../../utils/Commons";
import formatCurrency from "../../utils/FormatCurrencyVueFilter";

const FixedFeeOperation = {
  ADD: 'add',
  SUBSTRACT: 'substract'
}

export default {
		name: "AmountInput",
    // ATTENTION : isClientSelling indique que le CLIENT vend, donc le bureau ACHETE
		props: ['baseCurrency', 'foreignCurrency', 'isClientSelling', 'roundUp', 'sendingProject', 'nextButtonLabel', 'fixedFee'],
		data() {
			return {
				rand: null,
				clientGiveQuantity: '',
				clientGetQuantity: '',
				hasBeenRounded: false,
				needsRounding: false,
				nextDialog: false,
        errorAmountTooLow: false
			}
		},
		mounted() {
			this.rand = Math.random();
		},
		computed: {
			nodePrefix() {
				return "amount-input-" + this.rand + '-';
			},
			fromIso() {
				return this.isClientSelling ? this.foreignCurrency.iso.toUpperCase() : this.baseCurrency.iso.toUpperCase();
			},
			toIso() {
				return !this.isClientSelling ? this.foreignCurrency.iso.toUpperCase() : this.baseCurrency.iso.toUpperCase();
			},
			nextEnabled() {
				return !this.needsRounding && this.clientGiveQuantity > 0 && this.clientGetQuantity > 0;
			},
      fixedFeeInForeignCurrency() {
        return this.computeBaseToForeign(this.fixedFee, this.foreignCurrency, this.isClientSelling);
      }
		},
		watch: {
			foreignCurrency() {
				this.clientGiveQuantity = '';
				this.clientGetQuantity = '';
			},
			isClientSelling() {
				this.clientGiveQuantity = '';
				this.clientGetQuantity = '';
			},
			clientGiveQuantity(newVal) {
				// On vérifie que l'utilisateur est bien en train de modifier le champ base quantity (une modification par programmation peut aussi déclencher le watcher)
				// Important car une modification du champ clientGetQuantity va venir modifier clientGiveQuantity - et vice-versa)
				if (this.nodePrefix + 'base-input' === document.activeElement.id) {

          if (Commons.isNumeric(newVal)) {
            let newValInt = parseFloat(newVal);
            if (this.isClientSelling) {
              // Le client vend, clientGiveQuantity correspond à la devise étrangère, clientGetQuantity à la devise pivot
              if (newValInt <= this.fixedFeeInForeignCurrency) {
                this.errorAmountTooLow = true;
              } else {
                this.clientGetQuantity = this.computeForeignToBase(newValInt, this.foreignCurrency, this.isClientSelling, this.fixedFee, FixedFeeOperation.SUBSTRACT); // On soustrait le montant frais au résultat, puisque le résultat correspond à la somme que le client va récupérer de sa vente
                this.errorAmountTooLow = false;
              }
            } else {
              // Le client achète, clientGiveQuantity correspond à la devise pivot, clientGetQuantity à la devise étrangère
              if (newValInt <= this.fixedFee) {
                this.errorAmountTooLow = true;
              } else {
                this.clientGetQuantity = this.computeBaseToForeign(newValInt - this.fixedFee, this.foreignCurrency, this.isClientSelling); // On soustrait le montant frais au montant à changer, pour que le résultat corresponde au montant donné déduit des frais
                this.errorAmountTooLow = false;
              }
            }
          }  else {
            this.clientGetQuantity = '';
          }
				}
				this.hasBeenRounded = this.foreignCurrency.mini === 0; // Pas besoin d'arrondir s'il n'y a pas de valeur mini

        let amountInBaseCurrency = !this.isClientSelling ? this.clientGiveQuantity : this.clientGetQuantity;
        let quantityInForeignCurrency = !this.isClientSelling ? this.clientGetQuantity : this.clientGiveQuantity;
				this.$emit('change', amountInBaseCurrency, quantityInForeignCurrency);
			},
			clientGetQuantity(newVal) {
				// On vérifie que l'utilisateur est bien en train de modifier le champ target quantity (une modification par programmation peut aussi déclencher le watcher)
				// Important car une modification du champ clientGiveQuantity va venir modifier clientGetQuantity - et vice-versa)
				if (this.nodePrefix + 'target-input' === document.activeElement.id) {
					if (Commons.isNumeric(newVal)) {
            let newValInt = parseFloat(newVal);
            if (this.isClientSelling) {
              // Le client vend, clientGiveQuantity correspond à la devise étrangère, clientGetQuantity à la devise pivot
              if (newValInt <= 0) {
                this.errorAmountTooLow = true;
              } else {
                this.clientGiveQuantity = this.computeBaseToForeign(newValInt + this.fixedFee, this.foreignCurrency, this.isClientSelling); // On soustrait le montant frais au montant à changer, pour que le résultat corresponde au montant qu'il récupèrera déduit des frais
                this.errorAmountTooLow = false;
              }
            } else {
              // Le client achète, clientGiveQuantity correspond à la devise pivot, clientGetQuantity à la devise étrangère
              if (newValInt <= 0) {
                this.errorAmountTooLow = true;
              } else {
                this.clientGiveQuantity = this.computeForeignToBase(newValInt, this.foreignCurrency, this.isClientSelling, this.fixedFee, FixedFeeOperation.ADD); // On ajoute le montant des frais au montant à payer
                this.errorAmountTooLow = false;
              }
            }

					} else {
						this.clientGiveQuantity = '';
					}
				}
				this.hasBeenRounded = this.foreignCurrency.mini === 0; // Pas besoin d'arrondir s'il n'y a pas de valeur mini
			}
		},
		methods: {
			onInputFocus(e) {
				if (e.target.value == 0) {
					e.target.value = null;
				}
				this.$emit('focus', e)
			},
			onNextDialogOkClicked() {
				this.nextDialog = false;
				this.$emit('accepted')
			},
      formatCurrency(value, currency, forceNumberOfSignificantDigits) {
        return formatCurrency(value, currency, forceNumberOfSignificantDigits);
      },
      computeBaseToForeign(baseCurrencyQuantity, foreignCurrency, isClientSelling) {
        let rate = (isClientSelling ? foreignCurrency.buyRateReverse : foreignCurrency.sellRateReverse);
        let unroundedForeignQuantity = baseCurrencyQuantity * rate * foreignCurrency.base;
        // Pour l'arrondi, on souhaite arrondir à la plus petite unité de la devise étrangère, qu'on ne connait pas
        // Du coup, on l'estime à partir de la base. On va partir du principe qu'une chaque devise propose une coupure mini qui correspond à 1/100e de sa base. Donc 1 centime pour les bases 1, et 1 pour les bases 100
        let foreignCurrencySmallestUnitReverse = 1 / (foreignCurrency.base / 100);
        return (Math.round(unroundedForeignQuantity * foreignCurrencySmallestUnitReverse) / foreignCurrencySmallestUnitReverse);
      },
      computeForeignToBase(targetCurrencyQuantity, foreignCurrency, isClientSelling, optionalAddedFee, optionalFeeOperation) { // default optionalFeeOperation -> ADD
        let rate = (isClientSelling ? foreignCurrency.buyRate : foreignCurrency.sellRate);
        let unroundedBaseQuantity = targetCurrencyQuantity * rate / foreignCurrency.base;
        if (optionalAddedFee) {
          if (optionalFeeOperation === FixedFeeOperation.SUBSTRACT) {
            unroundedBaseQuantity -= optionalAddedFee;
          } else {
            unroundedBaseQuantity += optionalAddedFee;
          }
        }
        // Pour l'arrondi, on part du principe que la plus petite unité de devise pivot est le centime (on ne travaille qu'avec des pays pour lesquels c'est le cas)
        return (Math.round(unroundedBaseQuantity * 100) / 100).toFixed(2);
      },
			onRoundAmountsClicked() {
				document.getElementById(this.nodePrefix + 'base-input').blur();
				document.getElementById(this.nodePrefix + 'target-input').blur();
				this.hasBeenRounded = true;
			},
			getRoundedAmounts() {
				let newBaseAmount, newTargetAmount;
				if (this.foreignCurrency.mini > 0) {
					let toRemove = this.clientGetQuantity % this.foreignCurrency.mini;
					newTargetAmount = this.clientGetQuantity - toRemove;

					if (this.roundUp) {
						newTargetAmount += this.foreignCurrency.mini;
					}
					newBaseAmount = this.computeBaseQuantity(this.clientGetQuantity, this.foreignCurrency, this.isClientSelling);
				}
				return {base: newBaseAmount, target: newTargetAmount};
			}
		}
	}
</script>

<style scoped>

</style>
