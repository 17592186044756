import Vue from 'vue'
import VueI18n from 'vue-i18n'
import LocaleHelper from "../utils/LocaleHelper";

Vue.use(VueI18n);

let messages = LocaleHelper.getMessages();
let current = LocaleHelper.getCurrentLocale();

Vue.use(VueI18n)

export const i18n = new VueI18n({
	locale: 'fr', // set locale
	fallbackLocale: 'en',
	messages // set locale messages
})

const loadedLanguages = ['fr'] // our default language that is preloaded

if (current !== i18n.locale) {
	loadLanguageAsync(current);
}

function setI18nLanguage(lang) {
	i18n.locale = current
	document.querySelector('html').setAttribute('lang', lang)
	return lang
}

export function loadLanguageAsync(lang) {
	// If the same language
	if (i18n.locale === lang) {
		return Promise.resolve(setI18nLanguage(lang))
	}

	// If the language was already loaded
	if (loadedLanguages.includes(lang)) {
		return Promise.resolve(setI18nLanguage(lang))
	}

	// If the language hasn't been loaded yet
	return import(/* webpackChunkName: "lang-[request]" */ `@/i18n/${lang}.json`).then(
		messages => {
			i18n.setLocaleMessage(lang, messages.default)
			loadedLanguages.push(lang)
			return setI18nLanguage(lang)
		}
	).catch(e => {
		console.error(`Error while loading language ${lang}`)
	})
}

export default i18n;
