<template>
    <v-autocomplete :item-text="countryName" :item-value="c=>c.alpha2Code" :items="countries" :loading="loading" v-model="selection" :rules="rules" :label="label"/>
</template>

<script>
	import axios from 'axios';
	import LocaleHelper from "../../utils/LocaleHelper";

	export default {
		name: "CountryInput",
		data() {
			return {
				selection: null,
				countries: [],
				currentLocale: LocaleHelper.getCurrentLocale(),
				loading: false
			}
		},
		props: [
			'value',
			'disabled',
            'rules',
            'label'
		],
		watch: {
			selection(newVal) {
				this.$emit('input', newVal);
			}
		},
		created() {
			let countriesFromStorageJSON = window.localStorage.getItem("country-list");
			if (countriesFromStorageJSON != null) {
				let countriesFromStorage = JSON.parse(countriesFromStorageJSON);
				if (countriesFromStorage.length === 0) {
					this.fetchCountries();
				} else {
					this.countries = countriesFromStorage;
				}
			} else {
				this.fetchCountries();
			}

			this.selection = this.value;
		},
		methods: {
			fetchCountries() {
				this.loading = true;
				axios.get('/assets/light-countries.json').then((response) => {
					window.localStorage.setItem("country-list", JSON.stringify(response.data));
					this.countries = response.data;
				}).finally(() => this.loading = false);
			},
			countryName(country) {
				if (this.currentLocale.locale === 'en' || !country.translations[this.currentLocale]) {
					return country.name;
				} else {
					return country.translations[this.currentLocale];
				}
			}
		}
	}
</script>

<style scoped>

</style>
