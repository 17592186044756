<template>
    <div>
        <v-textarea v-if="shouldBeShown('origin')" :label="computeLabel('origin')" :rules="computeRules('origin')" v-model="form.origin"/>
        <v-textarea v-if="shouldBeShown('destination')" :label="computeLabel('destination')" :rules="computeRules('destination')" v-model="form.destination"/>
        <v-textarea v-if="shouldBeShown('compl')" :label="computeLabel('compl')" :rules="computeRules('compl')" v-model="form.compl"/>
    </div>
</template>

<script>

import AbstractFormPart from "./AbstractFormPart";

const EMPTY = {
		origin: "",
		destination: "",
		compl: ""
	};

	export default {
		name: "FormPartSourceOfFunds",
		extends: AbstractFormPart,
		data() {
			return {
				formId: 'sourceOfFunds',
				empty: EMPTY
			}
		}
	}
</script>

<style scoped>

</style>
