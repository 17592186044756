<template>
    <div>
        <v-snackbar :value="tokenExpirationAlertRemainingSeconds > 0 && tokenExpirationAlertRemainingSeconds <= tokenExpirationAlertThresholdInSec" :timeout="0" :color="tokenExpirationAlertRemainingSeconds > 30 ? 'warning' : 'error'" elevation="2" top > <!-- Si migration en vuetify >2.3, remplacer 0 par -1 -->
          <span class="mr-4">{{$t('transactionProjectView.text.sessionAboutToExpire')}}</span>
          <v-progress-circular :value="tokenExpirationAlertRemainingSeconds/tokenExpirationAlertThresholdInSec * 100"/>
        </v-snackbar>
        <v-overlay :value="tokenExpirationAlertRemainingSeconds != null && tokenExpirationAlertRemainingSeconds <= 0">
          <v-card class="ma-8" color="warning">
            <v-card-title>{{$t('transactionProjectView.text.sessionExpired')}}</v-card-title>
          </v-card>
        </v-overlay>
        <v-container>
            <v-row>
                <v-col>
                    <!-- Placeholder de chargement -->

                    <v-skeleton-loader :loading="tokenLoading" elevation="2" style="background-color:#fff;"
                                       type="list-item-three-line, list-item-two-line, image, table-tfoot">

                        <div v-if="tokenUnknownError">
                            <!-- Erreur inconnue -->
                            <v-card-text>{{$t('transactionProjectView.errors.loading')}}</v-card-text>
                            <v-card-actions>
                                <v-btn text @click="fetchTokenData()" color="warning">{{$t('common.retry')}}</v-btn>
                            </v-card-actions>
                        </div>

                        <div v-else-if="tokenExpired">
                            <!-- Jeton invalide ou expiré -->
                            <v-card-text>{{$t('transactionProjectView.errors.tokenExpired')}}</v-card-text>
                        </div>

                        <!-- Pour éviter les erreurs on rajoute une div, car le skeleton-loader ne changera pas d'état
                        s'il ne contient rien, et il ne peut contenir quelque chose que si le chargement est terminé -->
                        <div v-else>

                            <!-- Jeton valide -->
                            <div v-if="!tokenLoading">

                                <!--Premier etape - Achat ou vente -->
                                <v-card-title>{{tokenData.config.customization.displayedName}}</v-card-title>
                                <operation-type-selector :config="tokenData.config" :disabled="sendingProject"
                                                         @selected="onOperationTypeSelected"/>

                            </div>
                        </div>
                    </v-skeleton-loader>
                </v-col>
            </v-row>

            <!--Deuxième étape - Sélection de la devise -->
            <v-row id="row-second-step" v-if="operationTypeId">
                <v-col>
                    <v-card :disabled="sendingProject">
                      <v-card-subtitle v-if="isClientSelling">
                        {{$t('transactionProjectView.titles.selectCurrencyForeignToLocal', {iso:
                            tokenData.config.transactions.baseCurrency.iso.toUpperCase()})}}
                      </v-card-subtitle>
                      <v-card-subtitle v-if="!isClientSelling">
                            {{$t('transactionProjectView.titles.selectCurrencyLocalToForeign', {iso:
                            tokenData.config.transactions.baseCurrency.iso.toUpperCase()})}}
                      </v-card-subtitle>
                      <currency-selector :exchange-rates="tokenData.config.transactions.exchangeRates"
                                           :is-client-selling="isClientSelling"
                                           @selected="onCurrencySelected"/>
                    </v-card>
                </v-col>
            </v-row>

            <!--Troisième étape - Tarifs -->
            <v-row v-if="selectedCurrency">
                <v-col cols="12" id="row-third-step" sm="6">
                    <v-card :disabled="sendingProject">
                        <v-card-title>{{$t('transactionProjectView.titles.ourPrices')}}</v-card-title>
                        <currency-rate-display :base-currency="tokenData.config.transactions.baseCurrency"
                                               :foreign-currency="selectedCurrency" :is-client-selling="isClientSelling"/>
                    </v-card>
                </v-col>
                <!--Quatrième étape - Simulation -->
                <v-col cols="12" id="row-fourth-step" sm="6">
                    <v-card :disabled="sendingProject">
                        <v-card-text>{{$t('transactionProjectView.text.inputAmount', {fromIso: fromIso, toIso: toIso})}}
                        </v-card-text>
                        <amount-input :base-currency="tokenData.config.transactions.baseCurrency"
                                      :foreign-currency="selectedCurrency"
                                      :is-client-selling="isClientSelling"
                                      :round-up="tokenData.config.transactions.roundUp"
                                      :sending-project="sendingProject"
                                      :fixed-fee="tokenData.config.transactions.fixedFee"
                                      :next-button-label="showForm ? $t('common.continue') : $t('currencyForm.transmitToCounter')"
                                      @accepted="onAmountAccepted"
                                      @change="onAmountChange"
                                      @focus="onAmountInputFocus"
                        />
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <div v-if="showForm">
            <form-container :disabled="sendingProject || !hasAcceptedDisclaimer" :modules="formModules"
                            :required-fields="formRequiredFields" @change="onFormChange" :show-only-required-fields="showOnlyRequiredFields"
                            id="row-fifth-step"
            />
            <v-container>
                <v-row>
                    <v-col>
                        <v-btn :disabled="sendingProject || !hasAcceptedDisclaimer" :loading="sendingProject"
                               @click="onFormNextClicked" block
                               color="primary">{{$t('currencyForm.transmitToCounter')}}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-snackbar :bottom="true" color="error" v-model="projectSendError">
            {{$t('transactionProjectView.errors.projectSend')}}
            <v-btn :loading="sendingProject" @click="submitTransactionProject" dark text>{{$t('common.retry')}}</v-btn>
        </v-snackbar>
    </div>
</template>

<script>
import OperationTypeSelector from "../components/transaction/OperationTypeSelector";
import CurrencySelector from "../components/transaction/CurrencySelector";
import CurrencyRateDisplay from "../components/transaction/CurrencyRateDisplay";
import AmountInput from "../components/transaction/AmountInput";
import * as eases from 'vuetify/es5/services/goto/easing-patterns'
import FormContainer from "../components/identityform/FormContainer";

export default {
		name: "TransactionProject",
		components: {FormContainer, AmountInput, CurrencyRateDisplay, OperationTypeSelector, CurrencySelector},
		data() {
			return {
				token: null,
				tokenLoading: false,
				tokenUnknownError: false,
				tokenExpired: false,
				tokenData: null,
				tokenExpirationDate: null,
				tokenExpirationAlertRemainingSeconds: null,
        tokenExpirationAlertThresholdInSec: 60,
				operationTypeId: null,
				selectedCurrency: null,
				showForm: false,
				formRequiredFields: [],
				formModules: [],
        showOnlyRequiredFields: false,
				formData: null,
				amount: null,
				foreignCurrencyQuantity: null,
				hasAcceptedDisclaimer: false,
				projectSendError: false,
				sendingProject: false
			}
		},
		created() {
			this.fetchTokenData()
		},
		computed: {
			isClientSelling() {
				return this.operationTypeId === 2;
			},
			fromIso() {
				return this.isClientSelling ? this.selectedCurrency.iso.toUpperCase() : this.tokenData.config.transactions.baseCurrency.iso.toUpperCase()
			},
			toIso() {
				return !this.isClientSelling ? this.selectedCurrency.iso.toUpperCase() : this.tokenData.config.transactions.baseCurrency.iso.toUpperCase()
			}
		},
    timers: {
      updateExpirationAlerting: {time: 1000, autostart: false, repeat: true} // L'update sera lancé par prepareExpirationTimers, ça sera une boucle
    },
		methods: {
			fetchTokenData() {
				let url = new URL(window.location.origin + window.location.hash.substring(1));
				this.token = url.searchParams.get("token");
				this.tokenLoading = true;
				this.tokenUnknownError = false;
				this.tokenExpired = false;
				this.$http.post(this.$config.apiUrl + "/v1/tokens/configurationRequest", this.token, {headers: {"Content-Type": "text/plain"}})
					.then(r => {
						this.tokenData = r.data;
						this.tokenExpired = false;
						// On prépare le déclenchement de l'alerte d'expiration
            this.prepareExpirationTimers(this.tokenData.expirationDate);
					})
					.catch((e) => {
						if (e.response && e.response.status === 403) {
							this.tokenExpired = true;
						} else this.tokenUnknownError = true
					})
					.finally(() => this.tokenLoading = false);
			},
			onOperationTypeSelected(selectedId) {
				this.operationTypeId = selectedId;
				this.$nextTick(() => this.$vuetify.goTo("#row-second-step", {easing: eases.easeOutCubic}));
			},
			onCurrencySelected(selectedCurrency) {
				this.selectedCurrency = selectedCurrency;
				this.$nextTick(() => this.$vuetify.goTo("#row-third-step", {easing: eases.easeOutCubic}));
			},
			onAmountChange(baseQuantity, targetQuantity) {
				this.amount = baseQuantity;
				this.foreignCurrencyQuantity = targetQuantity;
				this.processAmount(baseQuantity)
			},
			onFormChange(form) {
				this.formData = form;
			},
			onAmountInputFocus() {
				this.$vuetify.goTo("#row-fourth-step", {easing: eases.easeOutCubic})
			},
			onAmountAccepted() {
				this.hasAcceptedDisclaimer = true;
				if (this.showForm) {
					this.$vuetify.goTo("#row-fifth-step", {easing: eases.easeOutCubic})
				} else {
					this.submitTransactionProject();
				}
			},
			onFormNextClicked() {
				this.submitTransactionProject();
			},
			processAmount(amount) {
				let result = null;
				let currentThreshold = 0;
				for (let i = 0; i < this.tokenData.config.transactions.formThresholds.length; i++) {
					let threshold = this.tokenData.config.transactions.formThresholds[i];
					if (amount >= threshold.threshold && threshold.threshold > currentThreshold) {
						currentThreshold = threshold.threshold;
						result = threshold;
					}
				}
				if (result) {
					this.formModules = result.config.modules;
					this.formRequiredFields = result.config.requiredFields;
          this.showOnlyRequiredFields = result.config.showOnlyRequiredFields === true;
					this.showForm = true;
				} else {
					this.showForm = false;
				}
			},
			submitTransactionProject() {
				this.sendingProject = true;
				this.projectSendError = false;
				let payload = {
					token: this.token,
					project: {
						lines: [{
							currencyIso: this.selectedCurrency.iso,
							selling: !this.isClientSelling, // Lorsqu'on envoi cette donnée à l'API, on se place sur le point du vue du commerçant. Donc si le client vend, le commerçant achète.
							quantity: this.foreignCurrencyQuantity,
							estimatedValue: this.amount
						}],
						identityForm: this.formData
					}
				};

				this.$http.post(this.$config.apiUrl + '/v1/tokens/projectsPushRequest', payload)
					.then((r) => {
						this.$router.push({
							name: 'projectrecap',
							params: {
								projectReference: r.data.projectReference,
								tokenData: this.tokenData,
								token: this.token,
								pushResponse: r
							}
						})
					})
					.catch(() => {
						this.projectSendError = true;
					})
					.finally(() => this.sendingProject = false)
			},
      prepareExpirationTimers(expirationDate) {
        try {
          this.tokenExpirationDate = new Date(expirationDate);
          this.$timer.start('updateExpirationAlerting')
        } catch (e) {
          console.error(e)
        }
      },
      updateExpirationAlerting() {
          if (this.tokenExpirationAlertRemainingSeconds === null) { // Si nous sommes dans la première boucle
            this.tokenExpirationAlertRemainingSeconds = Math.round((this.tokenExpirationDate - new Date()) / 1000);
          } else if (this.tokenExpirationAlertRemainingSeconds > 0) { // Le token n'est pas encore expiré, on continue la boucle
            this.tokenExpirationAlertRemainingSeconds--;
          } else { // Le token vient d'expirer, on peut arrêter le timer
            this.$timer.stop('updateExpirationAlerting')
          }
      }
		}
	}
</script>

<style scoped>

</style>
