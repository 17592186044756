<script>
import Commons from "../../utils/Commons";

export default {
		name: "AbstractFormPart",
		data() {
			return {
				form: {},
				init: false,
				emailValidate: v => ((/.+@.+\..+/.test(v)) || !v) || this.$t('common.invalidValue'),
				requiredRule: v => !!v || this.$t('common.requiredField')
			}
		},
		props: {
      initialData: {
        type: Object,
        default: () => ({})
      },
      requiredFields: {
        type: Array,
        default: () => []
      },
      showOnlyRequiredFields: {
        type: Boolean,
        default: false
      }
    },
		watch: {
			form: {
				deep: true,
				handler: function (v) {
					this.$emit('change', v, this.formId)
				}
			}
		},
		created() {
			this.form = this.empty;
			if (this.initialData) {
				Object.assign(this.form, this.initialData);
			}
			this.$nextTick(() => this.init = true);
		},
		computed: {
			baseTranslationKey() {
				return 'identityForm.' + this.formId + '.';
			},
			parentTranslationKey() {
				return 'identityForm.' + this.formId;
			}
		},
		methods: {
      shouldBeShown(name) {
        return this.requiredFields.length === 0 || !this.showOnlyRequiredFields || this.isFieldRequired(name);
      },
			computeLabel(name) {
				let tKey = name ? this.baseTranslationKey + name : this.parentTranslationKey;
				let label = this.$t(tKey);
				if (this.isFieldRequired(name)) {
					label += ' (' + this.$t('common.requiredFieldShort') + ')';
				}
				return label;
			},
			computeRules(name, defaultRules = []) {
				if (this.isFieldRequired(name)) {
					defaultRules.push(this.requiredRule)
				}
				return defaultRules;
			},
			computeSelectList(baseTranslationKey, sort = true) {
				let r = [];
				let key = this.baseTranslationKey + baseTranslationKey;
				for (const v in this.$t(key)) {
					r.push({value: v, text: (this.$t(key + '.' + v))});
					if (sort) {
						r.sort((a, b) => (Commons.compareObjectOnStrProperty(a, b, 'text')));
					}
				}
				return r;
			},
      isFieldRequired(fieldName) {
				return Array.isArray(this.requiredFields) && this.requiredFields.includes(this.formId + '.' + fieldName);
      },
			reset() {
				Object.assign(this.form, this.empty);
			},
			getEmpty() {
				return this.empty;
			},
			getData() {
				return this.form;
			}
		}
	}
</script>
