<template>
    <v-form>
        <v-container>
          <v-row v-if="requirementFriendly">
            <v-col>
                <v-alert type="info" elevation="2" class="mb-0">
                  {{ $t('currencyForm.pleaseFillIdentityForm') }}
                </v-alert>
            </v-col>
          </v-row>
          <form-row :disabled="disabled" :title="$t('identityForm.sourceOfFundsGroup')" v-if="activeModules.sourceOfFunds">
            <form-part-source-of-funds :initial-data="form.sourceOfFunds" :required-fields="requiredFields"
                                       @change="onFormPartChange" :show-only-required-fields="showOnlyRequiredFields"
                                       ref="sourceOfFunds"/>
          </form-row>
          <form-row :disabled="disabled" :title="$t('identityForm.naturalPersonGroup')" v-if="activeModules.naturalPerson">
              <form-part-natural-person :initial-data="form.naturalPerson" :required-fields="requiredFields"
                                          @change="onFormPartChange" ref="naturalPerson"
                                        :show-only-required-fields="showOnlyRequiredFields"/>
            </form-row>
          <form-row :disabled="disabled" :title="$t('identityForm.addressGroup')" v-if="activeModules.address">
              <form-part-address :initial-data="form.address" :required-fields="requiredFields"
                                   @change="onFormPartChange" :show-only-required-fields="showOnlyRequiredFields"
                                   ref="address"/>
            </form-row>
          <form-row :disabled="disabled" :title="legalEntityTitle" v-if="activeModules.legalEntity">
              <form-part-legal-entity :initial-data="form.legalEntity" :required-fields="requiredFields"
                                        @change="onFormPartChange" :show-only-required-fields="showOnlyRequiredFields"
                                        ref="legalEntity"/>
            </form-row>
          <form-row :disabled="disabled" :title="$t('identityForm.workSituationGroup')" v-if="activeModules.workSituation">
              <form-part-work-situation :initial-data="form.workSituation" :required-fields="requiredFields"
                                          @change="onFormPartChange" :show-only-required-fields="showOnlyRequiredFields"
                                          ref="workSituation"/>
            </form-row>
          <form-row :disabled="disabled" :title="$t('identityForm.businessRelationGroup')" v-if="activeModules.businessRelation">
              <form-part-business-relation :initial-data="form.businessRelation" :required-fields="requiredFields"
                                             @change="onFormPartChange" :show-only-required-fields="showOnlyRequiredFields"
                                             ref="businessRelation"/>
            </form-row>
            <form-row :disabled="disabled" v-if="activeModules.signature || activeModules.certify">
                <form-part-certify :required-fields="requiredFields" @change="onCertifyChange" :initial-data="form" v-if="activeModules.certify"/>
                <form-part-signature :is-required="true" @change="onFormPartChange" ref="signature" v-if="activeModules.signature"/>
            </form-row>
        </v-container>
    </v-form>
</template>

<script>
import FormPartNaturalPerson from "./FormPartNaturalPerson";
import FormPartLegalEntity from "./FormPartLegalEntity";
import FormPartWorkSituation from "./FormPartWorkSituation";
import FormPartBusinessRelation from "./FormPartBusinessRelation";
import FormPartSourceOfFunds from "./FormPartSourceOfFunds";
import FormPartSignature from "./FormPartSignature";
import FormRow from "./FormRow";
import FormPartAddress from "./FormPartAddress";
import FormPartCertify from "./FormPartCertify";

export default {
		name: "FormContainer",
		components: {
			FormPartCertify,
			FormPartAddress,
			FormRow,
			FormPartSignature,
			FormPartSourceOfFunds,
			FormPartBusinessRelation,
			FormPartWorkSituation,
			FormPartLegalEntity,
			FormPartNaturalPerson
		},
		data() {
			return {
				form: {
					naturalPerson: {},
					legalEntity: {},
					workSituation: {},
					businessRelation: {},
					address: {},
					sourceOfFunds: {},
					certify: false,
					signature: "",
				}
			}
		},
    props: {
      modules: {
        type: Array,
        required: true
      },
      requiredFields: {
        type: Array,
        required: true
      },
      disabled: {
        type: Boolean,
        default: false
      },
      requirementFriendly: {
        type: Boolean,
        default: true
      },
      showOnlyRequiredFields: {
        type: Boolean,
        default: false
      }
    },
		computed: {
			activeModules() {
				let r = {};
				if (this.modules && Array.isArray(this.modules)) {
					for (const module in this.form) {
						r[module] = this.modules.includes(module);
					}
                }
				return r;
			},
			legalEntityTitle() {
				if (this.form.legalEntity) {
					return this.form.legalEntity.companyName;
				} else return "";
			},
		},
		methods: {
			onFormPartChange(newVal, formId) {
				this.form[formId] = newVal;
				this.$emit('change', this.form);
			},
			onCertifyChange(newVal) {
				this.form.certify = newVal.certify;
				this.$emit('change', this.form);
			}
		}
	}
</script>

<style scoped>

</style>
