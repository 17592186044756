<template>
    <div>
        <v-radio-group v-if="shouldBeShown('civility')" :label="computeLabel('civility')" row v-model="form.civility">
            <v-radio :label="$t('identityForm.naturalPerson.civilityChoices.mr')" value="mr"/>
            <v-radio :label="$t('identityForm.naturalPerson.civilityChoices.mrs')" value="mrs"/>
        </v-radio-group>
        <v-text-field v-if="shouldBeShown('firstName')" :label="computeLabel('firstName')" :rules="computeRules('firstName')" v-model="form.firstName"/>
        <v-text-field v-if="shouldBeShown('birthName')" :label="computeLabel('birthName')" :rules="computeRules('birthName')" v-model="form.birthName"/>
        <v-text-field v-if="shouldBeShown('usageName')" :label="computeLabel('usageName')" :rules="computeRules('usageName')" v-model="form.usageName"/>
        <v-text-field v-if="shouldBeShown('profession')" :label="computeLabel('profession')" :rules="computeRules('profession')" v-model="form.profession"/>
        <v-text-field v-if="shouldBeShown('email')" :label="computeLabel('email')" :rules="computeRules('email', [emailValidate])" type="email" v-model="form.email"/>
        <v-text-field v-if="shouldBeShown('cellularPhone')" :label="computeLabel('cellularPhone')" :rules="computeRules('cellularPhone')" type="tel" v-model="form.cellularPhone"/>
        <v-text-field v-if="shouldBeShown('birthDate')" :label="computeLabel('birthDate')" :rules="computeRules('birthDate')" type="date" v-model="form.birthDate"/>
    </div>
</template>

<script>

import AbstractFormPart from "./AbstractFormPart";

const EMPTY = {
		civility: "",
		birthName: "",
		usageName: "",
		firstName: "",
		profession: "",
		email: "",
		birthDate: "",
		cellularPhone: ""
	};

	export default {
		name: "FormPartNaturalPerson",
        extends: AbstractFormPart,
        data() {
			return {
                formId: 'naturalPerson',
				empty: EMPTY
            }
        },
        watch: {
			'form.birthName': function (v) {
				if (this.init) {
                    this.form.usageName = v;
                }
			}
        }
	}
</script>

<style scoped>

</style>
